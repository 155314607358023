// https://github.com/christo-pr/dangerously-set-html-content
import React, { useEffect, useRef } from "react";
type DangerouslySetHtmlContentProps = {
  html: string;
};

const DangerouslySetHtmlContent: React.FC<DangerouslySetHtmlContentProps> = ({
  html,
}) => {
  // We remove 'dangerouslySetInnerHTML' from props passed to the div
  const divRef: any = useRef(null);

  useEffect(() => {
    if (!html || !divRef.current) throw new Error("html prop can't be null");

    const slotHtml = document.createRange().createContextualFragment(html); // Create a 'tiny' document and parse the html string
    divRef.current.innerHTML = ""; // Clear the container
    divRef.current.appendChild(slotHtml); // Append the new content
  }, [html, divRef]);

  // eslint-disable-next-line react/react-in-jsx-scope
  return <div ref={divRef} />;
};

export default DangerouslySetHtmlContent;
