import React from "react";
import "./baseline.css";

import { createTheme, ThemeProvider, ThemeOptions } from "@mui/material";

export interface ThemeProps {
  children: React.ReactNode;
  primaryColor?: string;
}

export const Theme = ({ children, primaryColor }: ThemeProps) => {
  const themeOptions: ThemeOptions = {
    breakpoints: {
      keys: ["xs", "sm", "md", "lg", "xl"],
      values: {
        xs: 0,
        sm: 500,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
      unit: "px",
    },
    palette: {
      primary: {
        main: primaryColor ? primaryColor : "#1652F0",
      },
      secondary: {
        main: "#05B169",
        contrastText: "#ffffff",
      },
    },
    typography: {
      fontFamily: [
        "-apple-system",
        "system-ui",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Oxygen",
        "Ubuntu",
        "Cantarell",
        '"Fira Sans"',
        '"Droid Sans"',
        "Arial",
        "sans-serif",
      ].join(","),
      fontSize: 14,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
      fontWeightBold: 700,
    },
    shape: {
      borderRadius: 8,
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: "none",
          },
          sizeLarge: {
            padding: "18px 22px",
            fontSize: "1.1rem",
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: 8,
            margin: "0",
          },
        },
      },
      MuiInputAdornment: {
        styleOverrides: {
          root: {
            "& .MuiTypography-root": {
              color: "rgba(0, 0, 0, 1) !important",
              fontSize: "1.8rem",
            },
          },
        },
      },
      // Targeting RadioGroup
      MuiFormGroup: {
        styleOverrides: {
          root: {
            margin: "20px 0 0 0",
            "& .MuiFormControlLabel-root": {
              margin: "5px 0",
            },
          },
        },
      },
      MuiFormControl: {
        styleOverrides: {
          root: {
            margin: "8px 0",
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          root: {
            border: "1px solid rgba(0, 0, 0, 0.23)",
            borderRadius: 8,
            margin: 0,
            padding: "7px 5px",
            "&.Mui-checked": {
              borderColor: primaryColor ? primaryColor : "#1652F0",
              backgroundColor: primaryColor ? primaryColor : "#e1f5fe",
            },
          },
          label: {
            "&.Mui-disabled": {
              // color: "rgba(0, 0, 0, 1)",
            },
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            color: "rgba(0, 0, 0, 0.87)",
            textAlign: "center",
            lineHeight: "1.3",
            "&.Mui-focused": {
              color: "rgba(0, 0, 0, 0.87)",
            },
            "&.MuiInputLabel-outlined": {
              color: "rgba(0, 0, 0, 0.45)",
            },
            "&.MuiInputLabel-shrink": {
              color: "rgba(0, 0, 0, 0.87)",
            },
          },
        },
      },
      MuiRadio: {
        styleOverrides: {
          root: {
            "&.Mui-checked": {
              "&, & + .MuiFormControlLabel-label": {
                color: primaryColor ? primaryColor : "#0d47a1",
              },
            },
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            color: "#212529",
            fontFamily: [
              "-apple-system",
              "system-ui",
              "BlinkMacSystemFont",
              '"Segoe UI"',
              "Roboto",
              '"Helvetica Neue"',
              "Oxygen",
              "Ubuntu",
              "Cantarell",
              '"Fira Sans"',
              '"Droid Sans"',
              "Arial",
              "sans-serif",
            ].join(","),
          },
          h1: {
            fontSize: "26px",
            margin: "30px auto",
            textAlign: "center",
            fontWeight: 400,
          },
          h2: {
            fontSize: "18px",
            lineHeight: "26px",
            margin: "-10px auto 35px",
            textAlign: "center",
          },
          h3: {
            fontSize: "18px",
            lineHeight: "26px",
            fontWeight: "bold",
            margin: "30px auto 20px",
          },
        },
      },
      MuiLinearProgress: {
        styleOverrides: {
          root: {
            margin: "20px 0 0 0",
          },
        },
      },
      MuiTable: {
        styleOverrides: {
          root: {
            background: "#fafafa",
          },
        },
      },
    },
  };
  console.log(primaryColor);
  const theme = createTheme(themeOptions);
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
