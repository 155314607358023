import React from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  RadioGroup,
} from "@mui/material";
import { Controller, Control } from "react-hook-form";

export type MkCheckboxGroupProps = {
  key: string;
  control: Control;
  id: string;
  name: string;
  label: string;
  options: Array<{
    id: string;
    label: string;
    value: string;
  }>;
};

export const MkCheckboxGroup = ({
  control,
  id,
  name,
  ...props
}: MkCheckboxGroupProps) => (
  <FormControl component="fieldset" fullWidth>
    <FormLabel component="legend" style={{ width: "100%" }}>
      <div dangerouslySetInnerHTML={{ __html: `${props.label}` }} />
    </FormLabel>
    <FormGroup>
      {props.options?.map((o) => (
        <Controller
          name={`${o.id}`}
          control={control}
          key={o.id}
          render={({ field }) => (
            <FormControlLabel
              control={<Checkbox {...field} />}
              label={<div dangerouslySetInnerHTML={{ __html: o.label }} />}
            />
          )}
        />
      ))}
    </FormGroup>
  </FormControl>
);
