import React from "react";
import { MakoArgTypes } from "../utils";

export type MkHtmlProps = {
  context?: any;
  html: string;
};

export const MkHtmlArgTypes: MakoArgTypes = {
  html: {
    control: "code",
    name: "HTML",
    description: "The HTML to render",
  },
  context: {
    control: "object",
    name: "Context",
    description: "The context to use for resolving variables",
  },
};

export const MkHtml = ({ html: rawHtml, context }: MkHtmlProps) => {
  function resolve(path: any, obj = self, separator = ".") {
    var properties = Array.isArray(path) ? path : path.split(separator);
    return properties.reduce((prev: any, curr: any) => prev && prev[curr], obj);
  }
  const html = rawHtml.replace(/\{\{([^}]+)\}\}/g, function (i, match) {
    return resolve(match, context);
  });
  return <div dangerouslySetInnerHTML={{ __html: `${html}` }} />;
};
