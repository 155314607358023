import React, { useEffect, useMemo, useReducer } from "react";
import {
  Autocomplete,
  Box,
  debounce,
  Grid,
  TextField,
  TextFieldProps,
  Typography,
} from "@mui/material";
import parse from "autosuggest-highlight/parse";
import { Controller, Control } from "react-hook-form";
import { LocationOn } from "@mui/icons-material";
import { MkPre } from "../MkPre/MkPre";

export const MkAddressFullArgTypes = {
  gmapsApiKey: { control: "text" },
  region: { control: "text" },
  countryRestriction: { control: "text" },
  name: { control: "text" },
  label: { control: "text" },
  placeholder: { control: "text" },
  defaultValue: { control: "text" },
  size: { control: "select", options: ["small", "medium"] },
  margin: { control: "select", options: ["none", "dense", "normal"] },
  variant: { control: "select", options: ["filled", "outlined", "standard"] },
  required: { control: "boolean" },
  autoFocus: { control: "boolean" },
  disabled: { control: "boolean" },
  fullWidth: { control: "boolean", defaultValue: true },
  startAdornment: { control: "text" },
  endAdornment: { control: "text" },
  helperText: { control: "text" },
  country: { control: "text" },
};

export type MkAddressFullProps = TextFieldProps & {
  gmapsApiKey: string;
  region?: string;
  countryRestriction?: string;
  country: any;
  control: Control;
  name: string;
  id: string;
  defaultValue?: string;
  onChange: (value: any) => void;
};

const loadScript = (src: string, position: HTMLElement, id: string) => {
  if (!position) {
    return;
  }
  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
};

interface MainTextMatchedSubstrings {
  offset: number;
  length: number;
}

interface Terms {
  offset: number;
  value: string;
}

interface StructuredFormatting {
  main_text: string;
  secondary_text: string;
  main_text_matched_substrings?: readonly MainTextMatchedSubstrings[];
}

interface PlacePredictionType {
  description: string;
  matched_substrings: MainTextMatchedSubstrings[];
  place_id: string;
  reference: string;
  structured_formatting: StructuredFormatting;
  terms: Terms[];
  types: string[];
}

interface AddressComponents {
  long_name: string;
  short_name: string;
  types: string[];
  postcode_localities: string[];
}

interface LatLng {
  lat: () => number;
  lng: () => number;
}

interface LatLngBounds {
  northeast: LatLng;
  southwest: LatLng;
}

interface Geometry {
  location: LatLng;
  location_type: string;
  viewport: LatLngBounds;
  bounds: LatLngBounds;
}

interface PlaceType {
  types: string[];
  formatted_address: string;
  address_components: AddressComponents[];
  partial_match: boolean;
  place_id: string;
  postcode_localities: string[];
  geometry: Geometry;
}

interface AddressType {
  street_number?: string;
  route?: string;
  locality?: string;
  postal_code?: string;
  state?: string;
  state_long?: string;
  country?: string;
  country_long?: string;
  lat?: number;
  lng?: number;
}

type State = {
  inputValue: string;
  address: AddressType;
  place_prediction: PlacePredictionType | null;
  place: PlaceType | null;
};

type Action =
  | { type: "setInputValue"; inputValue: string }
  | { type: "setAddress"; address: any }
  | { type: "setAddressKey"; key: string; value: string }
  | { type: "setPlacePrediction"; placePrediction: PlacePredictionType | null }
  | { type: "setPlace"; place: any }
  | { type: "setState"; state: State };

export const MkAddressFull = ({
  gmapsApiKey,
  region,
  countryRestriction,
  control,
  id,
  label,
  placeholder,
  variant,
  fullWidth,
  size,
  disabled,
  required,
  defaultValue,
}: MkAddressFullProps) => {
  const [options, setOptions] = React.useState<any>([]);
  const loaded = React.useRef(false);
  const autocompleteService = React.useRef(false);
  const geocoder = React.useRef(false);

  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${gmapsApiKey}&libraries=places&callback=Function.prototype`,
        document.querySelector("head") as HTMLElement,
        "google-maps"
      );
    }
    loaded.current = true;
  }

  return (
    <Controller
      name={id}
      control={control}
      rules={{
        required,
      }}
      render={({
        field: { onChange, onBlur, value, ref },
        formState,
        fieldState,
      }) => {
        const geocode = async ({
          placeId,
          address,
        }: {
          placeId?: string;
          address?: string;
        }) => {
          if (!geocoder.current) return;
          (geocoder.current as any).geocode(
            { placeId, address },
            (places: PlaceType[]) => {
              if (places.length === 0) return;
              dispatch({ type: "setPlace", place: places[0] });
              const address: AddressType = {};
              places[0].address_components.forEach((c: any) => {
                if (c.types.includes("route")) address.route = c.long_name;
                if (c.types.includes("street_number"))
                  address.street_number = c.long_name;
                if (c.types.includes("sublocality"))
                  address.locality = c.long_name;
                if (c.types.includes("locality"))
                  address.locality = c.long_name;
                if (c.types.includes("postal_town"))
                  address.locality = c.long_name;
                if (c.types.includes("postal_code"))
                  address.postal_code = c.long_name;
                if (c.types.includes("administrative_area_level_1")) {
                  address.state = c.short_name;
                  address.state_long = c.long_name;
                }
                if (c.types.includes("country")) {
                  address.country = c.short_name;
                  address.country_long = c.long_name;
                }
              });
              address.lat = places[0].geometry.location.lat();
              address.lng = places[0].geometry.location.lng();
              // document.activeElement?.blur();
              // onChange(res);
              dispatch({ type: "setAddress", address: address });
            }
          );
        };

        const [state, dispatch] = useReducer(
          (state: State, action: Action) => {
            switch (action.type) {
              case "setInputValue":
                return { ...state, inputValue: action.inputValue };
              case "setAddressKey":
                return {
                  ...state,
                  address: {
                    ...state.address,
                    [action.key]: action.value,
                  },
                };
              case "setAddress":
                return { ...state, address: action.address };
              case "setPlacePrediction":
                return { ...state, place_prediction: action.placePrediction };
              case "setPlace":
                return { ...state, place: action.place };
              case "setState":
                return { ...action.state };
              default:
                return state;
            }
          },
          {
            inputValue: defaultValue,
            address: null,
            place_prediction: null,
            place: null,
            ...value,
          }
        );

        const fetch = useMemo(
          () =>
            debounce(
              (
                request: {
                  input: string;
                  region?: string;
                  componentRestrictions?: { country: string };
                },
                callback: (results?: readonly PlaceType[]) => void
              ) => {
                (autocompleteService.current as any).getPlacePredictions(
                  request,
                  callback
                );
              },
              400
            ),
          []
        );

        useEffect(() => {
          let active = true;

          if (!autocompleteService.current && (window as any).google) {
            autocompleteService.current = new (
              window as any
            ).google.maps.places.AutocompleteService();
            geocoder.current = new (window as any).google.maps.Geocoder();
          }

          // If no autocompleteService, then we can't do anything
          if (!autocompleteService.current) return;
          // If no input value, then we empty the options and return
          if (!state.inputValue || state.inputValue === "")
            return setOptions([]);

          fetch(
            {
              input: state.inputValue,
              region,
              componentRestrictions: countryRestriction
                ? { country: countryRestriction }
                : undefined,
            },
            (results?: readonly PlaceType[]) => {
              if (!active) return;
              let newOptions: readonly PlaceType[] = [];
              if (results) newOptions = [...newOptions, ...results];
              setOptions(newOptions);
            }
          );

          return () => {
            active = false;
          };
        }, [state.inputValue, fetch]);

        useEffect(() => {
          onChange(state);
        }, [state]);

        return (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Autocomplete
                getOptionLabel={(option: any) =>
                  typeof option === "string" ? option : option.description
                }
                filterOptions={(x) => x}
                options={options}
                autoComplete
                disabled={disabled}
                includeInputInList
                filterSelectedOptions
                freeSolo
                onChange={(event: any, newValue: any) => {
                  setOptions(newValue ? [newValue, ...options] : options);
                  dispatch({
                    type: "setPlacePrediction",
                    placePrediction: newValue,
                  });
                  geocode({
                    placeId: newValue?.place_id,
                  });
                }}
                onInputChange={(event, newInputValue) => {
                  dispatch({
                    type: "setInputValue",
                    inputValue: newInputValue,
                  });
                  if (newInputValue === "") {
                    dispatch({ type: "setAddress", address: null });
                  }
                }}
                value={state.inputValue}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputRef={ref}
                    size={size}
                    label={label}
                    variant={variant}
                    fullWidth
                    placeholder={placeholder}
                    type="search"
                    autoComplete="off"
                    required={required}
                  />
                )}
                renderOption={renderOption}
              />
            </Grid>
            {state.address && (
              <>
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    name="street_number"
                    label="House #"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: state.address?.street_number,
                    }}
                    inputProps={{
                      required: true,
                    }}
                    value={state.address?.street_number || ""}
                    onChange={(e) =>
                      dispatch({
                        type: "setAddressKey",
                        key: "street_number",
                        value: e.target.value,
                      })
                    }
                    autoComplete="street-number"
                  />
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    fullWidth
                    name="route"
                    label="Street"
                    variant="outlined"
                    autoComplete="street-address"
                    value={state.address?.route || ""}
                    inputProps={{
                      required: true,
                    }}
                    InputLabelProps={{
                      shrink: state.address?.route,
                    }}
                    onChange={(e) =>
                      dispatch({
                        type: "setAddressKey",
                        key: "route",
                        value: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    name="locality"
                    label="City"
                    variant="outlined"
                    autoComplete="address-level2"
                    value={state.address?.locality || ""}
                    InputLabelProps={{
                      shrink: state.address?.locality,
                    }}
                    onChange={(e) =>
                      dispatch({
                        type: "setAddressKey",
                        key: "locality",
                        value: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    name="state"
                    label="State"
                    variant="outlined"
                    autoComplete="address-level1"
                    value={state.address?.state || ""}
                    InputLabelProps={{ shrink: state.address?.state }}
                    onChange={(e) =>
                      dispatch({
                        type: "setAddressKey",
                        key: "state",
                        value: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    name="postal_code"
                    label="Zip code"
                    variant="outlined"
                    autoComplete="postal-code"
                    value={state.address?.postal_code || ""}
                    InputLabelProps={{
                      shrink: state.address?.postal_code,
                    }}
                    onChange={(e) =>
                      dispatch({
                        type: "setAddressKey",
                        key: "postal_code",
                        value: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    name="state_long"
                    label="State"
                    variant="outlined"
                    autoComplete="address-level1"
                    value={state.address?.state_long || ""}
                    InputLabelProps={{ shrink: state.address?.state }}
                    onChange={(e) =>
                      dispatch({
                        type: "setAddressKey",
                        key: "state_long",
                        value: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    name="country"
                    label="Country"
                    variant="outlined"
                    autoComplete="country"
                    value={state.address?.country || ""}
                    InputLabelProps={{
                      shrink: state.address?.country,
                    }}
                    onChange={(e) =>
                      dispatch({
                        type: "setAddressKey",
                        key: "country",
                        value: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    fullWidth
                    name="country"
                    label="Country"
                    variant="outlined"
                    autoComplete="country"
                    value={state.address?.country_long || ""}
                    InputLabelProps={{
                      shrink: state.address?.country_long,
                    }}
                    onChange={(e) =>
                      dispatch({
                        type: "setAddressKey",
                        key: "country_long",
                        value: e.target.value,
                      })
                    }
                  />
                </Grid>
              </>
            )}
            {process.env.NODE_ENV === "development" && (
              <Grid item xs={12}>
                <MkPre collapsible isCollapsed>
                  {JSON.stringify(state, null, 2)}
                </MkPre>
              </Grid>
            )}
          </Grid>
        );
      }}
    />
  );
};

const renderOption = (
  props: React.HTMLAttributes<HTMLLIElement>,
  option: any
) => {
  const matches =
    option.structured_formatting.main_text_matched_substrings || [];

  const parts = parse(
    option.structured_formatting.main_text,
    matches.map((match: any) => [match.offset, match.offset + match.length])
  );

  return (
    <li {...props}>
      <Grid container alignItems="center">
        <Grid item sx={{ display: "flex", width: 44 }}>
          <LocationOn sx={{ color: "text.secondary" }} />
        </Grid>
        <Grid
          item
          sx={{
            width: "calc(100% - 44px)",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
        >
          {parts.map((part: any, index: any) => (
            <Box
              key={index}
              component="span"
              sx={{ fontWeight: part.highlight ? "bold" : "regular" }}
            >
              {part.text}
            </Box>
          ))}

          <Typography
            variant="body2"
            color="text.secondary"
            style={{ textOverflow: "ellipsis", overflow: "hidden" }}
          >
            {option.structured_formatting.secondary_text}
          </Typography>
        </Grid>
      </Grid>
    </li>
  );
};
