import React from "react";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { Controller, Control } from "react-hook-form";

export type MkRadioProps = {
  option: MkRadioGroupProps["options"][0];
  value?: any;
  label?: React.ReactNode;
  disabled?: boolean;
  onChange?: (...event: any[]) => void;
  onBlur?: (...event: any[]) => void;
  onClick?: (...event: any[]) => void;
  style?: React.CSSProperties;
};

export const MkRadio = ({
  option: o,
  value,
  disabled,
  onChange,
  onBlur,
  label: l,
  ...args
}: MkRadioProps) => {
  const Icon = o.icon ? (
    <div
      style={{
        height: 24,
        width: 24,
        textAlign: "center",
        fontSize: 20,
        color: "black",
      }}
    >
      {o.icon}
    </div>
  ) : undefined;

  const label = l ? (
    <div>{l}</div>
  ) : (
    <div dangerouslySetInnerHTML={{ __html: o.label }} />
  );

  return (
    <FormControlLabel
      id={o.id}
      className={o.value === value ? "Mui-checked" : ""}
      value={o.value}
      control={
        <Radio icon={Icon} checkedIcon={Icon} inputProps={{ id: o.id }} />
      }
      label={label}
      onChange={onChange}
      onBlur={onBlur}
      disabled={disabled}
      {...args}
    />
  );
};

export const MkRadioGroupArgTypes = {
  name: { control: "text" },
  label: { control: "text" },
  autoSubmit: { control: "boolean" },
};

export type MkRadioGroupProps = {
  id: string;
  key: string;
  control: Control;
  autoSubmit?: boolean;
  onSubmit?: (data: any) => void;
  name: string;
  label: string;
  options: Array<{
    id: string;
    label: string;
    value: string;
    disabled?: boolean;
    icon?: string | null;
  }>;
};

export const MkRadioGroup = ({
  id,
  control,
  autoSubmit = false,
  onSubmit,
  label,
  options,
  ...args
}: MkRadioGroupProps) => (
  <FormControl component="fieldset" fullWidth {...args}>
    <FormLabel component="legend">
      <div dangerouslySetInnerHTML={{ __html: `${label}` }} />
    </FormLabel>
    <Controller
      control={control}
      name={id}
      render={({ field: { onChange, onBlur, value, ref } }) => (
        <RadioGroup
          name={id}
          value={value || null}
          onChange={(e) => {
            if (autoSubmit && onSubmit) onSubmit({ [id]: e.target.value });
          }}
        >
          {options.map((o) => (
            <MkRadio
              key={o.id}
              onChange={onChange}
              onBlur={onBlur}
              option={o}
              value={value}
            />
          ))}
        </RadioGroup>
      )}
    />
  </FormControl>
);
