import React from "react";
import { Alert, Button } from "@mui/material";
import { ImportExport } from "@mui/icons-material";
import { Controller, Control } from "react-hook-form";

import { MkPre } from "../MkPre/MkPre";

export type MkFetchProps = {
  id: string;
  url: string;
  method?: "GET" | "POST" | "PUT" | "DELETE";
  body?: any;
  headers?: { [key: string]: string };
  build: boolean;
  context: any;
  control: Control;
  onSubmit?: (data: any) => void;
  onSuccess?: (response: any) => void;
  onError?: (error: any) => void;
};

export const MkFetchArgTypes = {
  url: {
    name: "Url",
    control: "text",
    description: "The URL to fetch",
    defaultValue: "https://localhost:8888/mock",
  },
  method: { control: "select", options: ["GET", "POST", "PUT", "DELETE"] },
  body: { control: "object" },
  headers: { control: "object" },
  onSuccess: { action: "success" },
  onError: { action: "error" },
};

export const MkFetch = ({
  id,
  url,
  method = "GET",
  body: rawBody,
  headers,
  build = false,
  context,
  control,
  onSubmit,
  onSuccess,
  onError,
}: MkFetchProps) => {
  console.log(control);
  return (
    <Controller
      name={id}
      control={control}
      render={({ field: { onChange } }) => {
        const [runs, setRuns] = React.useState<any>(0);
        const [response, setResponse] = React.useState<any>();
        const [error, setError] = React.useState<any>();

        React.useEffect(() => {
          setRuns(0);
          setResponse(undefined);
          setError(undefined);
          if (!build) setRuns(runs + 1);
        }, [url, method, rawBody, headers, onSuccess, onError, build]);

        const resolve = (path: any, obj = self, separator = ".") => {
          const keys = Array.isArray(path) ? path : path.split(separator);
          const value = keys.reduce(
            (prev: any, curr: any) => prev && prev[curr],
            obj
          );
          switch (typeof value) {
            case "string":
              return `"${value}"`;
            case "object":
              return JSON.stringify(value);
            default:
              return value;
          }
        };

        const body = rawBody
          ? JSON.stringify(rawBody).replace(/"\{\{([^}]+)\}\}"/g, (i, match) =>
              resolve(match, context)
            )
          : undefined;

        React.useEffect(() => {
          const fetchData = async () => {
            try {
              const response = await fetch(url, {
                method,
                body: body,
                headers: {
                  "Content-Type": "application/json",
                  ...headers,
                },
              });
              const data = await response.json();
              setResponse(data);
              onChange(data);
              console.log({ data });
              const formData = {
                [id]: JSON.stringify(data, null, 2),
              };
              console.log({ formData });
              onSubmit && onSubmit(formData);
            } catch (error) {
              setError(error);
              onError && onError(error);
            }
          };
          if (runs > 0) fetchData();
        }, [runs]);

        let severity: any = "info";
        if (error) {
          severity = "error";
        }
        if (response) {
          severity = "success";
        }

        return (
          <>
            <Alert
              severity={severity}
              icon={<ImportExport fontSize="inherit" />}
              action={
                <Button
                  color="primary"
                  size="small"
                  variant="outlined"
                  onClick={() => setRuns(runs + 1)}
                >
                  {`⚡️ Fetch (${runs})`}
                </Button>
              }
            >
              {`${method} ${url}`}
            </Alert>
            <MkPre collapsible isCollapsed>
              {JSON.stringify(
                {
                  error: error ? error.message : undefined,
                  response,
                  request: {
                    url,
                    method,
                    headers,
                    body,
                    rawBody,
                    context,
                  },
                },
                null,
                2
              )}
            </MkPre>
          </>
        );
      }}
    />
  );
};
