import React from "react";
import {
  Autocomplete,
  Box,
  debounce,
  Grid,
  TextField,
  TextFieldProps,
  Typography,
} from "@mui/material";
import parse from "autosuggest-highlight/parse";
import { Controller, Control } from "react-hook-form";
import { LocationOn } from "@mui/icons-material";

export const MkAddressArgTypes = {
  gmapsApiKey: { control: "text" },
  region: { control: "text" },
  countryRestriction: { control: "text" },
  name: { control: "text" },
  label: { control: "text" },
  placeholder: { control: "text" },
  defaultValue: { control: "text" },
  size: { control: "select", options: ["small", "medium"] },
  margin: { control: "select", options: ["none", "dense", "normal"] },
  variant: { control: "select", options: ["filled", "outlined", "standard"] },
  required: { control: "boolean" },
  autoFocus: { control: "boolean" },
  disabled: { control: "boolean" },
  fullWidth: { control: "boolean", defaultValue: true },
  startAdornment: { control: "text" },
  endAdornment: { control: "text" },
  helperText: { control: "text" },
  country: { control: "text" },
};

export type MkAddressProps = TextFieldProps & {
  gmapsApiKey: string;
  region?: string;
  countryRestriction?: string;
  country: any;
  control: Control;
  name: string;
  id: string;
  defaultValue?: string;
  onChange: (value: any) => void;
};

const loadScript = (src: string, position: HTMLElement, id: string) => {
  if (!position) {
    return;
  }
  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
};

interface MainTextMatchedSubstrings {
  offset: number;
  length: number;
}
interface StructuredFormatting {
  main_text: string;
  secondary_text: string;
  main_text_matched_substrings?: readonly MainTextMatchedSubstrings[];
}
interface PlaceType {
  description: string;
  structured_formatting: StructuredFormatting;
}

export const MkAddress = ({
  gmapsApiKey,
  region,
  countryRestriction,
  control,
  id,
  name,
  defaultValue,
  onChange,
  label,
  placeholder,
  variant,
  fullWidth,
  size,
  disabled,
  required,
  ...args
}: MkAddressProps) => {
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] =
    React.useState<string | undefined>(defaultValue);
  const [options, setOptions] = React.useState<any>([]);
  const loaded = React.useRef(false);
  const autocompleteService = React.useRef(false);

  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${gmapsApiKey}&libraries=places&callback=Function.prototype`,
        document.querySelector("head") as HTMLElement,
        "google-maps"
      );
    }
    loaded.current = true;
  }

  const fetch = React.useMemo(
    () =>
      debounce(
        (
          request: {
            input: string;
            region?: string;
            componentRestrictions?: { country: string };
          },
          callback: (results?: readonly PlaceType[]) => void
        ) => {
          (autocompleteService.current as any).getPlacePredictions(
            request,
            callback
          );
        },
        400
      ),
    []
  );

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && (window as any).google) {
      autocompleteService.current = new (
        window as any
      ).google.maps.places.AutocompleteService();
    }

    // If no autocompleteService, then we can't do anything
    if (!autocompleteService.current) return;
    // If no input value, then we empty the options and return
    if (!inputValue || inputValue === "")
      return setOptions(value ? [value] : []);

    fetch(
      {
        input: inputValue,
        region,
        componentRestrictions: countryRestriction
          ? { country: countryRestriction }
          : undefined,
      },
      (results?: readonly PlaceType[]) => {
        if (!active) return;
        let newOptions: readonly PlaceType[] = [];
        if (value) newOptions = [value];
        if (results) newOptions = [...newOptions, ...results];
        setOptions(newOptions);
      }
    );

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  return (
    <Controller
      name={id}
      control={control}
      rules={{
        required,
      }}
      render={({
        field: { onChange, onBlur, value, ref },
        formState,
        fieldState,
      }) => (
        <Autocomplete
          id="google-map-demo"
          getOptionLabel={(option: any) =>
            typeof option === "string" ? option : option.description
          }
          filterOptions={(x) => x}
          options={options}
          autoComplete
          disabled={disabled}
          includeInputInList
          filterSelectedOptions
          freeSolo
          onChange={(event: any, newValue: any) => {
            setOptions(newValue ? [newValue, ...options] : options);
            setValue(newValue);
            typeof newValue === "object"
              ? onChange(newValue.description)
              : onChange(newValue);
            // onChange(newValue);
          }}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
            onChange(newInputValue);
          }}
          value={value}
          renderInput={(params) => (
            <TextField
              {...params}
              inputRef={ref}
              size={size}
              label={label}
              variant={variant}
              fullWidth={fullWidth}
              placeholder={placeholder}
              type="search"
              autoComplete="off"
              required={required}
            />
          )}
          renderOption={(props, option) => {
            const matches =
              option.structured_formatting.main_text_matched_substrings || [];

            const parts = parse(
              option.structured_formatting.main_text,
              matches.map((match: any) => [
                match.offset,
                match.offset + match.length,
              ])
            );

            return (
              <li {...props}>
                <Grid container alignItems="center">
                  <Grid item sx={{ display: "flex", width: 44 }}>
                    <LocationOn sx={{ color: "text.secondary" }} />
                  </Grid>
                  <Grid
                    item
                    sx={{
                      width: "calc(100% - 44px)",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {parts.map((part: any, index: any) => (
                      <Box
                        key={index}
                        component="span"
                        sx={{ fontWeight: part.highlight ? "bold" : "regular" }}
                      >
                        {part.text}
                      </Box>
                    ))}

                    <Typography
                      variant="body2"
                      color="text.secondary"
                      style={{ textOverflow: "ellipsis", overflow: "hidden" }}
                    >
                      {option.structured_formatting.secondary_text}
                    </Typography>
                  </Grid>
                </Grid>
              </li>
            );
          }}
        />
      )}
    />
  );
};
