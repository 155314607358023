import React from "react";
import { Step as LibStep, MkPre } from "lib";
import { useMutation, useApolloClient } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { v4 as uuid } from "uuid";

import { INSERT_LEAD, GET_LEAD, WEBHOOK, UPSERT_ANSWERS } from "../graphql";
import {
  GetFormsQuery,
  GetLeadQuery,
  InsertLeadMutation,
  InsertLeadMutationVariables,
  UpsertAnswersMutation,
  UpsertAnswersMutationVariables,
  InsertAnswerMutationVariables,
} from "../generated/graphql";

const Step: React.FC<{
  form: GetFormsQuery["forms_forms"][0];
  step: GetFormsQuery["forms_forms"][0]["steps"][0];
  lead?: GetLeadQuery["forms_leads_by_pk"];
}> = ({ form, step, lead }) => {
  const navigate = useNavigate();
  const client = useApolloClient();

  let initialData: any = {};
  if (lead) {
    step.blocks
      .filter((b) => !["text", "button"].includes(b.block_type_id))
      .forEach(
        (b) =>
          (initialData[b.id] = lead.answers.filter(
            (a) => a.block_id === b.id && a.value_v2
          )[0]?.value_v2.value)
      );
  }

  const [insertLead] =
    useMutation<InsertLeadMutation, InsertLeadMutationVariables>(INSERT_LEAD);

  const [upsertAnswers] =
    useMutation<UpsertAnswersMutation, UpsertAnswersMutationVariables>(
      UPSERT_ANSWERS
    );

  const [webhook] = useMutation(WEBHOOK);

  const onSubmit = (data: any) => {
    console.log({ formData: data });

    const leadId = lead?.id || uuid();

    const newAnswers: InsertAnswerMutationVariables[] = Object.keys(data).map(
      (k) => ({
        id: lead?.answers.filter((a) => a.block_id === k)[0]?.id || uuid(),
        lead_id: leadId,
        block_id: k,
        value_v2: {
          type: typeof data[k],
          value: data[k],
        },
      })
    );

    const oldAnswers =
      lead?.answers.filter(
        (oldAnswer) =>
          !newAnswers.map((newAnswer) => newAnswer.id).includes(oldAnswer.id)
      ) || [];

    console.log({
      oldAnswers,
      newAnswers,
    });

    // Create or update lead
    if (!lead) {
      // Read all accessible cookies
      const cookies = Object.fromEntries(
        document.cookie
          .split("; ")
          .map((v) => v.split(/=(.*)/s).map(decodeURIComponent))
      );
      insertLead({
        variables: {
          lead: {
            id: leadId,
            form_id: step.form_id,
            cookies,
            landing_page_url: window.location.href,
            referer_url: document.referrer,
            user_agent: navigator.userAgent,
            answers: {
              data: newAnswers.map((a) => ({
                ...a,
                lead_id: undefined,
              })),
            },
          },
        },
      });
      setTimeout(() => navigate(`/${form.slug}/${step.slug}/${leadId}`), 15);
    } else {
      upsertAnswers({
        variables: {
          answers: newAnswers,
        },
      });
    }

    // Update the cache
    client.writeQuery({
      query: GET_LEAD,
      variables: { id: leadId },
      data: {
        forms_leads_by_pk: {
          __typename: "forms_leads",
          id: leadId,
          answers: [...oldAnswers, ...newAnswers].map((a) => ({
            ...a,
            __typename: "forms_answers",
          })),
        },
      },
    });

    // Obtain new answers to calculate next step
    const freshAnswers = client.readQuery<GetLeadQuery>({
      query: GET_LEAD,
      variables: {
        id: leadId,
      },
    })?.forms_leads_by_pk?.answers;

    // Submit webhook if present
    if (step.webhook_send) {
      webhook({
        variables: {
          lead_id: leadId,
          step_id: step.id,
        },
      });
    }

    // Calculate next step based on display conditions
    if (step.order < form.steps.length - 1) {
      let nextSlug: string | null = null;
      let i = step.order + 1;
      while (!nextSlug) {
        if (form.steps[i].display_conditions.length) {
          if (
            form.steps[i].display_conditions.reduce((prev, condition) => {
              const answer = freshAnswers?.filter(
                (l) => l.block_id === condition.block_id
              )[0]?.value_v2;
              return prev && condition.values.includes(answer);
            }, true)
          ) {
            nextSlug = form.steps[i].slug;
          } else {
            i++;
          }
        } else {
          nextSlug = form.steps[i].slug;
        }
      }
      setTimeout(() => navigate(`/${form.slug}/${nextSlug}/${leadId}`), 15);
    }
  };

  return (
    <>
      <LibStep
        step={step}
        initialData={initialData}
        lead={lead ? lead : undefined}
        onSubmit={(data) => {
          console.log({ data });
          onSubmit(data);
        }}
      />
      {process.env.NODE_ENV === "development" && (
        <MkPre collapsible isCollapsed dark>
          {JSON.stringify({ lead }, null, 2)}
        </MkPre>
      )}
    </>
  );
};

export default Step;
