import { gql } from "@apollo/client";

export const GET_FORMS = gql`
  query GetForms($slug: String = "%") {
    forms_forms(where: { slug: { _ilike: $slug } }) {
      id
      name
      slug
      gtm_id
      header_image_url
      header_image_max_height
      header_image_max_width
      show_progress_bar
      head_code
      body_code
      steps(order_by: { order: asc }) {
        id
        form_id
        name
        order
        slug
        webhook_send
        webhook_url
        blocks(order_by: { order: asc }) {
          id
          order
          block_type_id
          label
          options(order_by: { order: asc }) {
            id
            order
            value
            label
            icon
          }
          props
        }
        display_conditions {
          block_id
          created_at
          id
          operator
          step_id
          updated_at
          values
          block {
            label
          }
        }
      }
    }
  }
`;

export const GET_FORM = gql`
  query GetForm($id: uuid!) {
    forms_forms_by_pk(id: $id) {
      id
      name
      steps(order_by: { order: asc }) {
        id
        form_id
        name
        order
        slug
        blocks(order_by: { order: asc }) {
          id
        }
      }
    }
  }
`;

export const INSERT_LEAD = gql`
  mutation InsertLead($lead: forms_leads_insert_input!) {
    insert_forms_leads_one(object: $lead) {
      id
      answers {
        id
        lead_id
        block_id
        value_v2
      }
    }
  }
`;

export const GET_LEAD = gql`
  query GetLead($id: uuid!) {
    forms_leads_by_pk(id: $id) {
      id
      answers {
        id
        block_id
        lead_id
        value_v2
      }
    }
  }
`;

export const UPDATE_ANSWER = gql`
  mutation UpdateAnswer($lead_id: uuid!, $block_id: uuid!, $value_v2: jsonb) {
    update_forms_answers(
      where: { block_id: { _eq: $block_id }, lead_id: { _eq: $lead_id } }
      _set: { value_v2: $value_v2 }
    ) {
      affected_rows
      returning {
        id
        lead_id
        block_id
        value_v2
        lead {
          id
          answers {
            id
            block_id
            lead_id
            value_v2
          }
        }
      }
    }
  }
`;

export const INSERT_ANSWER = gql`
  mutation InsertAnswer(
    $id: uuid!
    $lead_id: uuid!
    $value_v2: jsonb!
    $block_id: uuid!
  ) {
    insert_forms_answers_one(
      object: {
        id: $id
        lead_id: $lead_id
        value_v2: $value_v2
        block_id: $block_id
      }
    ) {
      id
      lead_id
      block_id
      value_v2
      lead {
        id
        answers {
          id
          block_id
          lead_id
        }
      }
    }
  }
`;

export const UPSERT_ANSWERS = gql`
  mutation UpsertAnswers($answers: [forms_answers_insert_input!] = []) {
    insert_forms_answers(
      objects: $answers
      on_conflict: { constraint: answers_pkey, update_columns: [value_v2] }
    ) {
      affected_rows
      returning {
        id
        lead_id
        block_id
        value_v2
        lead {
          id
          answers {
            id
            block_id
            lead_id
            value_v2
          }
        }
      }
    }
  }
`;

export const WEBHOOK = gql`
  mutation Webhook($lead_id: uuid!, $step_id: uuid!) {
    webhook(lead_id: $lead_id, step_id: $step_id)
  }
`;
