import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Container } from "@mui/material";
import { Theme } from "lib";
import { HelmetProvider } from "react-helmet-async";

import Apollo from "./Providers/Apollo";
import Forms from "./Components/Forms";
import Form from "./Components/Form";

function App() {
  return (
    <BrowserRouter>
      <HelmetProvider>
        <Apollo>
          <Theme>
            <Container maxWidth="sm">
              <Routes>
                <Route path="/:formSlug/*" element={<Form />} />
                <Route path="/" element={<Forms />} />
              </Routes>
            </Container>
          </Theme>
        </Apollo>
      </HelmetProvider>
    </BrowserRouter>
  );
}

export default App;
