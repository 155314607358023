import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import { ButtonGroup, Button } from "@mui/material";
import { MkPre } from "lib";

import { GET_LEAD } from "../graphql";
import Loader from "./Loader";
import Step from "./Step";

import { GetLeadQuery, GetFormsQuery } from "../generated/graphql";

type LeadParams = { stepSlug: string; leadId: string };

const Lead: React.FC<{ form: GetFormsQuery["forms_forms"][0] }> = ({
  form,
}) => {
  const { stepSlug, leadId } = useParams<LeadParams>();

  const { error, loading, data } = useQuery<GetLeadQuery>(GET_LEAD, {
    variables: { id: leadId },
  });

  if (loading) return <Loader />;
  if (error) return <pre>{JSON.stringify(error, null, 2)}</pre>;
  if (!data?.forms_leads_by_pk) return <div>404 - No Lead Found</div>;

  const lead = data.forms_leads_by_pk;

  const step = form?.steps.find((s) => s.slug === stepSlug);
  if (!step) return <div>404 - No Step Found</div>;

  return (
    <>
      <Step form={form} step={step} lead={lead} />

      {process.env.NODE_ENV === "development" && (
        <>
          <ButtonGroup
            variant="outlined"
            aria-label="outlined button group"
            style={{
              marginTop: 20,
              width: "100%",
              overflowX: "scroll",
              whiteSpace: "nowrap",
            }}
          >
            <Button component={Link} to="/">
              ⬅
            </Button>
            <Button
              style={{ padding: "5px 30px" }}
              component={Link}
              to={`/${form?.slug}`}
            >
              Start
            </Button>
            {form?.steps.map((s) => (
              <Button
                component={Link}
                to={`/${form.slug}/${s.slug}/${leadId}`}
                key={s.slug}
                style={{ padding: "5px 75px" }}
              >
                {s.name}
              </Button>
            ))}
          </ButtonGroup>
          <MkPre dark collapsible isCollapsed>
            {JSON.stringify({ lead, form }, null, 2)}
          </MkPre>
        </>
      )}
    </>
  );
};

export default Lead;
