import React from "react";
import { InputAdornment, TextField, TextFieldProps } from "@mui/material";
import { Controller, Control } from "react-hook-form";

export const MkTextFieldArgTypes = {
  // id: { control: "text" },
  type: {
    control: "select",
    name: "Type",
    description: "The native HTML type of the input",
    options: [
      "date",
      "datetime-local",
      "email",
      "month",
      "number",
      "password",
      "search",
      "text",
      "time",
      "tel",
      "url",
      "week",
    ],
  },
  name: {
    control: "text",
    name: "Name",
    description:
      "The name of the input, used to identify the data in the submissions/leads/results",
  },
  label: {
    control: "text",
    name: "Label",
    description: "The label to display above the input",
  },
  placeholder: {
    control: "text",
    name: "Placeholder",
    description: "The placeholder to display inside the input if empty",
  },
  defaultValue: {
    control: "text",
    name: "Default Value",
    description: "The default value of the input",
  },
  /* color: {
    control: "select",
    options: ["primary", "secondary", "error", "info", "success", "warning"],
  }, */
  size: {
    control: "select",
    options: ["small", "medium"],
    name: "Size",
    description: "The size of the input",
  },
  margin: {
    control: "select",
    options: ["none", "dense", "normal"],
    name: "Margin",
    description: "The margin of the input",
  },
  variant: {
    control: "select",
    options: ["filled", "outlined", "standard"],
    name: "Variant",
    description: "The variant of the input",
  },
  required: {
    control: "boolean",
    name: "Required",
    description: "If the input is required",
  },
  autoComplete: {
    control: "select",
    name: "Auto Complete",
    description: "The native HTML autocomplete attribute",
    options: [
      "on",
      "off",
      "name",
      "honorific-prefix",
      "given-name",
      "additional-name",
      "family-name",
      "honorific-suffix",
      "nickname",
      "organization-title",
      "username",
      "new-password",
      "current-password",
      "one-time-code",
      "organization",
      "street-address",
      "address-line1",
      "address-line2",
      "address-line3",
      "address-level1",
      "address-level2",
      "address-level3",
      "address-level4",
      "country",
      "country-name",
      "postal-code",
      "cc-name",
      "cc-given-name",
      "cc-additional-name",
      "cc-family-name",
      "cc-number",
      "cc-exp",
      "cc-exp-month",
      "cc-exp-year",
      "cc-cvc",
      "cc-csc",
      "cc-type",
      "transaction-currency",
      "language",
      "bday",
      "bday-day",
      "bday-month",
      "bday-year",
      "sex",
      "url",
      "photo",
      "tel",
      "tel-country-code",
      "tel-national",
      "tel-area-code",
      "tel-local",
      "tel-local-prefix",
      "tel-local-suffix",
      "tel-extension",
      "email",
      "impp",
    ],
  },
  autoFocus: {
    control: "boolean",
    name: "Auto Focus",
    description: "If true, the input will be focused during the first mount",
  },
  disabled: {
    control: "boolean",
    name: "Disabled",
    description: "If true, the input will be disabled",
  },
  fullWidth: {
    control: "boolean",
    name: "Full Width",
    description:
      "If true, the input will take up the full width of its container",
  },
  startAdornment: {
    control: "text",
    name: "Start Adornment",
    description: "Start adornment for this component",
  },
  endAdornment: {
    control: "text",
    name: "End Adornment",
    description: "End adornment for this component",
  },
  // error: { control: "boolean" },
  // FormHelperTextProps: { control: "object" },
  helperText: {
    control: "text",
    name: "Helper Text",
    description: "The helper text content",
  },
  multiline: {
    control: "boolean",
    name: "Multiline",
    description:
      "If true, a auto-resizing textarea element will be rendered instead of an input",
  },
  rows: {
    control: "number",
    name: "Rows",
    description:
      "Number of rows to display when multiline option is set to true",
  },
  maxRows: {
    control: "number",
    name: "Max Rows",
    description:
      "Maximum number of rows to display when multiline option is set to true",
  },
  minRows: {
    control: "number",
    name: "Min Rows",
    description:
      "Minimum number of rows to display when multiline option is set to true",
  },
  // select: { control: "boolean" },
  // value: { control: "text" },
};

export type MkTextFieldProps = TextFieldProps & {
  control: Control;
  id: string;
  name: string;
  endAdornment: string;
  startAdornment: string;
};

export const MkTextField = ({
  endAdornment,
  startAdornment,
  id,
  name,
  fullWidth = true,
  ...props
}: MkTextFieldProps) => {
  let pattern: string | undefined;
  let inputMode:
    | "text"
    | "none"
    | "search"
    | "tel"
    | "url"
    | "email"
    | "numeric"
    | "decimal"
    | undefined;
  switch (props.type) {
    case "text":
      pattern = ".*";
      inputMode = "text";
      break;
    case "number":
      pattern = "[0-9]*";
      inputMode = "numeric";
      break;
    case "email":
      pattern = "[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$";
      inputMode = "email";
      break;
    case "tel":
      pattern = "+[0-9]*";
      inputMode = "tel";
      break;
    case "url":
      pattern = "https?://.+";
      inputMode = "url";
      break;
    default:
      break;
  }
  console.log({
    type: props.type,
    pattern,
    inputMode,
  });
  return (
    <Controller
      name={id}
      control={props.control}
      rules={{
        required: props.required,
      }}
      render={({
        field: { onChange, onBlur, value, ref },
        formState,
        fieldState,
      }) => (
        <TextField
          InputProps={{
            endAdornment: endAdornment ? (
              <InputAdornment position="end">{endAdornment}</InputAdornment>
            ) : undefined,
            startAdornment: startAdornment ? (
              <InputAdornment position="start">{startAdornment}</InputAdornment>
            ) : undefined,
          }}
          inputRef={ref}
          onChange={(e) => {
            if (props.type === "number") {
              onChange(parseInt(e.target.value));
            } else if (props.type === "date") {
              onChange(new Date(e.target.value).toISOString().slice(0, 10));
            } else if (props.type === "datetime-local") {
              onChange(new Date(e.target.value).toISOString().slice(0, 16));
            } else {
              onChange(e.target.value);
            }
          }}
          onBlur={onBlur}
          value={value}
          error={typeof fieldState.error !== "undefined"}
          helperText={fieldState.error ? "Please enter a valid value" : ""}
          fullWidth={fullWidth}
          inputProps={{
            pattern,
            inputMode,
          }}
          InputLabelProps={
            ["date", "datetime-local", "month", "time", "week"].includes(
              props.type as string
            )
              ? { shrink: true }
              : undefined
          }
          {...props}
        />
      )}
    />
  );
};
