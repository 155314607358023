// This component shows a loading spinner while the data is loading.
// The spinner is centered in the middle of the parent component.

import { Box, CircularProgress } from "@mui/material";

const Loader = () => {
  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        margin: { xs: 8, lg: 16, xl: 32 },
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default Loader;
