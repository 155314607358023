import React from "react";
import { useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import { Button, ButtonGroup, Typography } from "@mui/material";
import { MkPre } from "lib";

import Loader from "./Loader";
import { GET_FORMS } from "../graphql";
import { GetFormsQuery } from "../generated/graphql";

const Forms = () => {
  const { error, loading, data } = useQuery<GetFormsQuery>(GET_FORMS);

  if (loading) return <Loader />;
  if (error) return <pre>{JSON.stringify(error, null, 2)}</pre>;

  return (
    <>
      <Typography variant="h1">Forms</Typography>
      <ButtonGroup orientation="vertical" style={{ width: "100%" }}>
        {data?.forms_forms.map((f) => (
          <Button component={Link} to={`/${f.slug}`} key={f.slug}>
            {f.name}
          </Button>
        ))}
      </ButtonGroup>
      {process.env.NODE_ENV === "development" && (
        <MkPre dark isCollapsed collapsible>
          {JSON.stringify(data, null, 2)}
        </MkPre>
      )}
    </>
  );
};

export default Forms;
