// A material-ui CheckBox component controlled by react-hook-form.

import React from "react";
import { Checkbox, FormControl, FormControlLabel } from "@mui/material";
import { Controller, Control } from "react-hook-form";

export const MkCheckboxArgTypes = {
  // id: { control: "text" },
  name: { control: "text" },
  label: { control: "text" },
  fullWidth: { control: "boolean" },
  defaultValue: { control: "boolean" },
};

export type MkCheckboxProps = {
  key: string;
  control: Control;
  id: string;
  name: string;
  fullWidth?: boolean;
  label: string;
  defaultValue: boolean;
};

export const MkCheckbox = ({
  control,
  id,
  fullWidth = true,
  defaultValue = false,
  ...props
}: MkCheckboxProps) => (
  <FormControl fullWidth={fullWidth}>
    <Controller
      name={id}
      control={control}
      key={id}
      defaultValue={defaultValue}
      render={({ field }) => (
        <FormControlLabel
          control={<Checkbox {...field} checked={field.value} />}
          label={<div dangerouslySetInnerHTML={{ __html: props.label }} />}
        />
      )}
    />
  </FormControl>
);
