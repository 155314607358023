import React, { useEffect } from "react";
import { useQuery } from "@apollo/client";
import {
  Routes,
  Route,
  Navigate,
  useParams,
  useLocation,
} from "react-router-dom";
import { LinearProgress } from "@mui/material";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { Helmet } from "react-helmet-async";
import { GTMProvider, useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";

import InnerHTML from "./DangerouslySetHtmlContent";
import logo from "../logo.svg";
import { GET_FORMS } from "../graphql";
import { GetFormsQuery } from "../generated/graphql";
import Loader from "../Components/Loader";
import Lead from "./Lead";
import Step from "./Step";

const ScrollToTop = () => {
  const { pathname } = useLocation();
  const gtm = useGTMDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
    gtm({
      event: "pageview",
    });
  }, [pathname, gtm]);

  return null;
};

const Form = () => {
  const { formSlug } =
    useParams<{
      formSlug: string;
    }>();
  const location = useLocation();
  // Trick to prevent warning on unmounted for the transition
  const nodeRef = React.useRef(null);

  // Get form data
  const { error, loading, data } = useQuery<GetFormsQuery>(GET_FORMS, {
    variables: { slug: formSlug },
  });

  // Inject head code if present
  const head_code = data?.forms_forms[0]?.head_code;
  useEffect(() => {
    if (!head_code) return;
    const slotHtml = document.createRange().createContextualFragment(head_code);
    document.head.appendChild(slotHtml);
  }, [head_code]);

  // Loading & catching errors
  if (loading) return <Loader />;
  if (error) return <pre>{JSON.stringify(error, null, 2)}</pre>;
  if (data?.forms_forms.length === 0) return <div>404 - No Form Found</div>;

  const form = data?.forms_forms[0];

  if (!form?.steps?.length) return <div>404 - No Steps Found</div>;
  const stepSlug = location.pathname.split("/")[2];
  const step = form?.steps.filter((s) => s.slug === stepSlug)[0];
  const progress =
    (((step?.order || 0) + 1) / (form?.steps?.length || 1)) * 100 - 5;

  return (
    <GTMProvider
      state={{
        id: form?.gtm_id || (process.env.REACT_APP_DEFAULT_GTM_ID as string),
      }}
    >
      <ScrollToTop />
      <Helmet>
        <title>{form?.name}</title>
      </Helmet>
      {form?.body_code && <InnerHTML html={form?.body_code} />}
      <img
        src={form?.header_image_url || logo}
        alt="logo"
        style={{
          margin: "20px auto",
          maxWidth: form?.header_image_max_width || "400px",
          maxHeight: form?.header_image_max_height || "40px",
          display: "block",
        }}
      />
      {form?.show_progress_bar && (
        <LinearProgress variant="determinate" value={progress} />
      )}
      <TransitionGroup exit={false}>
        <CSSTransition
          appear
          key={location.key}
          classNames="fade"
          timeout={500}
          nodeRef={nodeRef}
        >
          <div ref={nodeRef}>
            <Routes>
              {/* Render the first step without any lead */}
              <Route
                path={form?.steps[0]?.slug}
                element={<Step form={form} step={form?.steps[0]} />}
              />
              {/* We redirect every step that is naked (no lead) to the first step */}
              <Route
                path=":stepSlug"
                element={
                  <Navigate
                    to={`/${form?.slug}/${form?.steps[0]?.slug}`}
                    replace
                  />
                }
              />
              {/* Render a lead in any step */}
              <Route
                path=":stepSlug/:leadId/*"
                element={<Lead form={form} />}
              />
              {/* We redirect naked form to the first step */}
              <Route
                path=""
                element={<Navigate to={`${form?.steps[0]?.slug}`} replace />}
              />
            </Routes>
          </div>
        </CSSTransition>
      </TransitionGroup>
    </GTMProvider>
  );
};

export default Form;
