import React from "react";
import { Alert } from "@mui/material";
import { useForm } from "react-hook-form";

import { MkButton } from "../MkButton/MkButton";
import { MkCheckbox } from "../MkCheckbox/MkCheckbox";
import { MkFetch } from "../MkFetch/MkFetch";
import { MkPhoneInput } from "../MkPhoneInput/MkPhoneInput";
import { MkRadioGroup } from "../MkRadioGroup/MkRadioGroup";
import { MkTextField } from "../MkTextField/MkTextField";
import { MkAddress } from "../MkAddress/MkAddress";
import { MkAddressFull } from "../MkAddressFull/MkAddressFull";
import { MkHtml } from "../MkHtml/MkHtml";

type Lead = {
  __typename?: "forms_leads";
  id: any;
  answers:
    | Array<{
        __typename?: "forms_answers";
        id: any;
        block_id: any;
        lead_id: any;
        value?: string | null | undefined;
      }>
    | null
    | undefined;
};

type Step = {
  __typename?: "forms_steps";
  id: any;
  form_id: any;
  name: string;
  order: number;
  slug: string;
  initialData?: any;
  blocks: Array<{
    __typename?: "forms_blocks";
    id: any;
    order: number;
    block_type_id: string;
    label?: string | null | undefined;
    props?: any;
    options?: Array<{
      __typename?: "forms_options";
      id: any;
      order: number;
      value: string;
      label: string;
    }>;
  }>;
  display_conditions: Array<{
    __typename?: "forms_display_conditions";
    block_id: any;
    created_at: any;
    id: any;
    operator: string;
    step_id: any;
    updated_at: any;
    values: any;
    block: { __typename?: "forms_blocks"; label?: string | null | undefined };
  }>;
};

export interface StepProps {
  step: Step;
  lead?: Lead;
  initialData: any;
  onSubmit: (data: any) => void;
}

export const Step = ({ step, lead, initialData, onSubmit }: StepProps) => {
  const { handleSubmit, control } = useForm<FormData>({
    defaultValues: initialData,
  });

  return (
    <form onSubmit={handleSubmit((data) => onSubmit(data))}>
      {step.blocks.map((block) => {
        switch (block.block_type_id) {
          case "text":
            return (
              <div
                key={block.id}
                dangerouslySetInnerHTML={{ __html: `${block.label}` }}
              />
            );
          case "input-text":
            return (
              <MkTextField
                {...block.props}
                id={block.id}
                key={block.id}
                control={control}
              />
            );
          case "input-radio":
            return (
              <MkRadioGroup
                {...block.props}
                id={block.id}
                key={block.id}
                control={control}
                options={block.options}
                label={block.label}
                onSubmit={handleSubmit((data) => onSubmit(data))}
              />
            );
          case "fetch":
            return (
              <MkFetch
                {...block.props}
                id={block.id}
                key={block.id}
                control={control}
                onSubmit={handleSubmit((data) => onSubmit(data))}
                context={{
                  lead,
                }}
              />
            );
          case "input-checkbox":
            return (
              <MkCheckbox
                {...block.props}
                id={block.id}
                key={block.id}
                control={control}
              />
            );
          case "input-phone":
            return (
              <MkPhoneInput
                {...block.props}
                id={block.id}
                key={block.id}
                control={control}
              />
            );
          case "input-address":
            return (
              <MkAddress
                {...block.props}
                id={block.id}
                key={block.id}
                control={control}
              />
            );
          case "input-address-full":
            return (
              <MkAddressFull
                {...block.props}
                id={block.id}
                key={block.id}
                control={control}
              />
            );
          case "html":
            return (
              <MkHtml {...block.props} key={block.id} context={{ lead }} />
            );
          case "button":
            return <MkButton id={block.id} key={block.id} {...block.props} />;
          default:
            return (
              <Alert key={block.id} severity="error">
                Error: no block matching:{" "}
                <i>
                  <b>{block.block_type_id}</b>
                </i>
              </Alert>
            );
        }
      })}
    </form>
  );
};
