import React from "react";
import { Button, ButtonProps } from "@mui/material";

export type MkButtonProps = ButtonProps & {
  label: string;
  url: string;
  build: boolean;
  context: any;
  behavior: "next" | "link";
};

export const MkButtonArgTypes = {
  label: {
    control: "text",
    description: "The label of the button",
  },
  behavior: {
    control: "select",
    options: ["next", "link"],
    description: "What happens after the button is clicked",
  },
  href: {
    control: "text",
    if: { arg: "behavior", eq: "link" },
    description: "The URL to navigate to",
  },
  variant: {
    control: "select",
    options: ["contained", "outlined", "text"],
    description: "The variant to use",
  },
  color: {
    control: "select",
    options: ["primary", "secondary", "error", "info", "success", "warning"],
    description: "The color of the component",
  },
  size: {
    control: "select",
    options: ["small", "medium", "large"],
    description: "The size of the component",
  },
  fullWidth: {
    control: "boolean",
    description:
      "If true, the button will take up the full width of its container",
  },
  disabled: {
    control: "boolean",
    description: "If true, the button will be disabled",
  },
  disableElevation: {
    control: "boolean",
    description: "If true, no elevation is used",
  },
  disableFocusRipple: {
    control: "boolean",
    description: "If true, the keyboard focus ripple will be disabled",
  },
  disableRipple: {
    control: "boolean",
    description: "If true, the ripple effect will be disabled",
  },
  endIcon: {
    control: "text",
    description: "Element placed after the children",
  },
  startIcon: {
    control: "text",
    description: "Element placed before the children",
  },
  context: {
    control: "object",
    description: "The context object to use",
  },
  onClick: { action: "click" },
};

function resolve(path: any, obj = self, separator = ".") {
  var properties = Array.isArray(path) ? path : path.split(separator);
  return properties.reduce((prev: any, curr: any) => prev && prev[curr], obj);
}

export const MkButton = ({
  href,
  label = "Button",
  build = false,
  context,
  behavior = "next",
  ...args
}: MkButtonProps) => {
  // This replaces {{variable}} with the value from context
  var html = label.replace(/\{\{([^}]+)\}\}/g, function (i, match) {
    return resolve(match, context);
  });
  const clickBehaviorProps: any = {
    type: behavior === "next" ? "submit" : "button",
    component: behavior === "link" ? "a" : undefined,
  };
  if (behavior === "link") {
    clickBehaviorProps["href"] = href;
  }
  if (build) {
    clickBehaviorProps["onClick"] = (e: any) => {
      e.preventDefault();
    };
  }
  return (
    <Button {...clickBehaviorProps} {...args}>
      <div dangerouslySetInnerHTML={{ __html: build ? label : `${html}` }} />
    </Button>
  );
};
