import React, { forwardRef } from "react";
import { TextField, TextFieldProps } from "@mui/material";
import { Controller, Control } from "react-hook-form";
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import Input from "react-phone-number-input/input";

export const MkPhoneInputArgTypes = {
  country: {
    control: "text",
    description:
      "If country is specified then the phone number can only be input in 'national' (not 'international') format, and will be parsed as a phone number belonging to the country. Must be a supported country code. Example: country='US'",
  },
  international: {
    control: "boolean",
    description: `If country is specified and international property is true then the phone number can only be input in "international" format for that country. By default, the "country calling code" part (+1 when country is US) is not included in the input field: that could be changed by passing withCountryCallingCode property (see below). So, if country is US and international property is not passed then the phone number can only be input in the "national" format for US ((213) 373-4253). But if country is "US" and international property is true then the phone number can only be input in the "international" format for US (213 373 4253) without the "country calling code" part (+1). This could be used for implementing phone number input components that show "country calling code" part before the input field and then the user can fill in the rest of their phone number digits in the input field.`,
  },
  withCountryCallingCode: {
    control: "boolean",
    description: `If country is specified and international property is true then the phone number can only be input in "international" format for that country. By default, the "country calling code" part (+1 when country is US) is not included in the input field. To change that, pass withCountryCallingCode property, and it will include the "country calling code" part in the input field. See the demo for an example.`,
  },
  defaultCountry: {
    control: "text",
    description: `If defaultCountry is specified then the phone number can be input both in "international" format and "national" format. A phone number that's being input in "national" format will be parsed as a phone number belonging to the defaultCountry. Must be a supported country code. Example: defaultCountry="US".`,
  },
  name: { control: "text" },
  label: { control: "text" },
  placeholder: { control: "text" },
  defaultValue: { control: "text" },
  size: { control: "select", options: ["small", "medium"] },
  margin: { control: "select", options: ["none", "dense", "normal"] },
  variant: { control: "select", options: ["filled", "outlined", "standard"] },
  required: { control: "boolean" },
  autoComplete: {
    control: "select",
    options: [
      "on",
      "off",
      "tel",
      "tel-national",
      "tel-area-code",
      "tel-local",
      "tel-local-prefix",
      "tel-local-suffix",
      "tel-country-code",
      "tel-extension",
    ],
  },
  autoFocus: { control: "boolean" },
  disabled: { control: "boolean" },
  fullWidth: { control: "boolean" },
};

export type MkPhoneInputProps = TextFieldProps & {
  country: any;
  international: boolean;
  withCountryCallingCode: boolean;
  defaultCountry: any;
  control: Control;
  id: string;
  name: string;
};

const InputComponent = forwardRef<HTMLInputElement, any>((props, ref) => (
  <TextField
    {...props}
    inputRef={ref}
    helperText={props.error ? "Please enter a valid phone number" : ""}
  />
));

export const MkPhoneInput = ({
  control,
  id,
  country,
  international,
  defaultCountry,
  ...args
}: MkPhoneInputProps) => (
  <Controller
    name={id}
    control={control}
    rules={{
      required: true,
      validate: (value) => isValidPhoneNumber(value.toString()),
    }}
    render={({ field: { onChange, value, ref }, fieldState }) =>
      international ? (
        <PhoneInput
          // @ts-ignore
          onChange={onChange}
          value={value}
          error={fieldState.error}
          country={country}
          international={international}
          defaultCountry={defaultCountry}
          placeholder="Enter phone number"
          inputComponent={InputComponent}
          {...args}
        />
      ) : (
        <Input
          // @ts-ignore
          onChange={onChange}
          value={value}
          error={fieldState.error}
          country={country}
          defaultCountry={defaultCountry}
          placeholder="Enter phone number"
          // @ts-ignore
          inputComponent={InputComponent}
          {...args}
        />
      )
    }
  />
);
