// A very simple component that renders a pre element with overflow scroll and monospaced font.
import React from "react";
import { Collapse } from "@mui/material";

export const MkPre = ({
  children,
  dark = false,
  collapsible = false,
  isCollapsed = false,
}: {
  children?: string;
  dark?: boolean;
  collapsible?: boolean;
  isCollapsed?: boolean;
}) => {
  const [expanded, setExpanded] = React.useState(!isCollapsed);
  const handleExpandClick = () => {
    if (collapsible) setExpanded(!expanded);
  };
  return (
    <pre
      style={{
        overflowX: "scroll",
        fontFamily: "monospace",
        color: dark ? "white" : "black",
        backgroundColor: dark ? "#263238" : "#fafafa",
        fontSize: "12px",
        padding: "0.5em",
        border: "1px solid",
        borderColor: dark ? "black" : "#ccc",
        borderRadius: "0.25rem",
        whiteSpace: "pre",
        position: "relative",
        cursor: collapsible ? "pointer" : "default",
      }}
      onClick={handleExpandClick}
    >
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        {children}
      </Collapse>
      {!expanded && children?.replace(/\s+/g, " ")}
    </pre>
  );
};
